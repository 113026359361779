@import "../mixin";

.taniya {
  color: black;
  font-family: serif;
  min-width: 1000px;
  @include mobile {
    min-width: 340px;
  }
}
.title {
  font-size: 32px;
  margin-top: 70px;
  .border {
    border-bottom: 3px double gray;
    padding: 3px 80px;
  }
}
p {
  margin: 0%;
  font-size: 16px;
}
.main-page {
  background-image: url("../assets/jpeg/b076.jpeg");
  text-align: center;
  padding-top: 100px;
  padding-bottom: 50px;
  @include mobile {
    padding-top: 40px;
    min-width: 340px;
  }
}
.contents {
  padding-top: 110px;
  margin-top: -110px;
}
.explain {
  display: flex;
  width: 70%;
  margin: 0px auto;
  align-items: center;
  padding: 10px 0px;
  max-width: 1000px;
  @include mobile {
    // display: block;
    width: initial;
  }
  img {
    width: 400px;
    @include mobile {
      width: 250px;
    }
  }
  p {
    padding: 20px 10px;
  }
}
.explain1,
.explain3 {
  @include mobile {
    flex-direction: column;
  }
}
.explain2 {
  @include mobile {
    flex-direction: column-reverse;
  }
}
.menu {
  .category {
    font-size: 22px;
    // margin-bottom: 20px;
  }
  .category-drink {
    margin-top: 20px;
  }
}
.food-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0px auto;
  //   width: 850px;
  @include mobile {
    display: block;
    width: initial;
  }
  .food {
    // display: flex;
    // width: 400px;
    padding: 10px;
    @include mobile {
      display: block;
      width: initial;
    }
    h3 {
      //   border-bottom: 1px solid black;
      @include mobile {
        border: none;
        margin: 0;
      }
    }
  }
  img {
    width: 200px;
    @include mobile {
      width: 250px;
    }
  }
}
.food-more {
  width: 80%;
  margin: 0px auto;
}

.drink-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  margin: 0px auto;
  width: 850px;
  @include mobile {
    display: block;
    width: initial;
    text-align: -webkit-center;
  }
  .drink {
    width: 250px;
    @include mobile {
      width: 80%;
    }
  }
  .drink-menu {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid black;
  }
  h4 {
    border-bottom: 2px solid black;
    text-align: center;
  }
  p {
    padding: 5px 0px;
  }
}
.info {
  @include mobile {
    padding: 0px 20px;
  }
}
.info-flex {
  display: flex;
  gap: 10px;
  justify-content: center;
  @include mobile {
    display: block;
  }
  .gaikan {
    width: 400px;
    height: auto;
    @include mobile {
      width: 80%;
      margin: 10px auto;
    }
    img {
      width: 100%;
    }
  }
}
table {
  margin: 0px auto;
  text-align: left;
  border-collapse: collapse;
  @include mobile {
    width: 100%;
  }

  td {
    border-bottom: 1px solid black;
    padding: 20px;
    font-size: 16px;
    @include mobile {
      padding: 10px 10px 10px 0px;
    }
  }
  .td-width {
    @include mobile {
      width: 70px;
    }
  }
}
.access {
  @include mobile {
    padding: 110px 20px 0;
  }
}
iframe {
  @include mobile {
    width: 80%;
  }
}
.access-info {
  display: flex;
  justify-content: center;
  gap: 10px;
  @include mobile {
    display: block;
  }
  table {
    width: 400px;
    margin: initial;
    height: 60px;
    @include mobile {
      width: 100%;
    }
  }
}

button {
  display: none;
  @include mobile {
    display: block;
    margin: 20px auto 0px;
    padding: 10px 30px;
    background: transparent;
  }
}
.list-pc {
  @include mobile {
    display: none;
  }
}
.list-sp {
  display: none;

  @include mobile {
    display: block;
  }
}

.recommend {
  background-color: #f5f3ed;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin: 20px;
  padding: 20px;
  text-align: center;
}

.recommend h3 {
  color: #a53e2c;
  font-size: 24px;
  font-family: "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
  margin-bottom: 10px;
}

.recommend p {
  color: #666;
  font-size: 16px;
  font-family: "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
  margin-bottom: 5px;
}

.recommend .price {
  color: #a53e2c;
  font-size: 24px;
  font-weight: bold;
  margin-top: 10px;
}
