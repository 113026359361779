@import "../mixin";

header {
  background-color: #555555;
  color: white;
  position: fixed;
  width: 100%;
  top: 0;
}
.pc {
  @include mobile {
    display: none;
  }
}
.header-top {
  display: flex;
  justify-content: space-between;
  width: 850px;
  margin: 0px auto;
  h1 {
    margin: 10px 30px;
    span {
      font-size: 20px;
    }
  }
  .reserve {
    align-items: center;
    display: flex;
    p {
      margin: 0%;
    }
    .tel {
      margin-left: 30px;
      font-size: 25px;
    }
  }
}

.list {
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0;
  .first {
    border-left: 1px solid white;
  }
  li {
    padding: 5px 70px;
    margin-bottom: 10px;
    border-right: 1px solid white;
    a {
      color: white;
      text-decoration: none;
    }
  }
}

.sp {
  display: none;

  h1 {
    font-size: 10px;
    span {
      font-size: 20px;
    }
  }
  @include mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 30px;
  }
}
.icon {
  padding-top: 7px;
  display: flex;

  button {
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 3px;
  }
}
.mark {
  background-color: #eeeeee;
  border-radius: 50%;
  padding: 5px;
}

.hamburger {
  width: 100%;
  height: 400px;
  background-color: black;
  position: absolute;
  top: 0;
  left: 0;
  button {
    position: absolute;
    right: 0px;
    background-color: transparent;
    border: none;
  }
  ul {
    list-style: none;
    margin: 100px auto 0px;
    padding: 0;

    li {
      margin-bottom: 30px;
      text-align: center;
      a {
        color: white;
        text-decoration: none;
      }
    }
  }
}
