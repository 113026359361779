@import "../mixin";

footer {
  background-color: #555555;
  color: white;
  width: 100%;
  text-align: center;
  @include mobile {
    min-width: 340px;
  }
  p {
    margin: 0%;
  }
  a {
    color: white;
  }
  .inquiry {
    padding-top: 30px;
    font-size: 20px;
    @include mobile {
      font-size: 15px;
    }
  }
  .tel {
    padding-bottom: 30px;
    font-size: 25px;
    @include mobile {
      font-size: 20px;
    }
  }
  .attention {
    padding-bottom: 30px;
    @include mobile {
      font-size: 15px;
      padding: 0px 20px 30px;
    }
  }
  .sign {
    font-size: 10px;
  }
}
